<template>
    <!-- list-tool-bar 定义了列表页的操作工具栏的普遍结构样式的抽象，即：
        （见UI图）左右分栏结构，左边部分靠左，右边部分靠右。
        
        往组件里面添加元素时，默认放入左边，如果其slot属性为 "right" 会放
        入右边，左右两个容器都是 flex 容器 -->
    <div class="list-tool-bar">
        <div class="list-title">
            <span>{{routeTitle}}</span>
            <div class="title-append">
                <slot name="title-append"></slot>
            </div>
        </div>
        <div>
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListToolBar',

    data () {
        return {
            routeTitle: ""
        }
    },

    created () {
        const {matched} = this.$route,
            len = matched.length,
            route = matched[len-1],
            title = route?.meta?.title || "";
        this.routeTitle = title;
    }
}
</script>

<style lang='scss' scoped>

    .list-tool-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            align-items: center;
        }
    }

    .list-title {
        display: flex;
        align-items: center;
        padding-left: 8px;
        border-left: 2px solid #FD9A17;

        .title-append {
            margin-left: 30px;
        }
    }

</style>